:root {
  --fontFamily: 'Open Sans', sans-serif;
  --headingFontFamily: 'Open Sans', sans-serif;
  --heraBlueColor: #00a0df;
  --linkColor: #4a93fc;
  --mainColor: #bc5a2e;
  --sidebarColor: #232b28;
  --aareRiverColor: #00b69b;
  --blackColor: #333;
  --paragraphColor: #096285;
  --whiteColor: #ffffff;
  --fontSize: 15px;
  --transition: 0.5s;
  --appBorderColor: #0000001f;
  --borderBoxShadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  --borderWhiteBoxShadow: rgba(255, 255, 255, 0.05) 0px 6px 24px 0px,
    rgba(255, 255, 255, 0.08) 0px 0px 0px 1px;

  .lpx-theme-dark,
  .lpx-theme-light {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-dropdown-link-hover-bg);
    }
  }

  .lpx-theme-dark {
    --lpx-card-bg: #14171c;

    .ngx-datatable.material {
      background: var(--lpx-card-bg);

      .datatable-body-cell,
      .datatable-header-cell {
        color: white !important;
      }

      .datatable-header-cell {
        background: var(--lpx-card-bg);
      }
    }

    .new-order {
      h5,
      p {
        color: white;
      }
    }
  }

  .lpx-theme-light {
    .main-content {
      background-color: #f5f5f5;
    }
  }
}
