.card {
  background: var(--lpx-card-bg);
}

.dropdown-menu {
  background: var(--lpx-card-bg);
}

ngb-modal-window {
  .modal-content {
    background: var(--lpx-card-bg);
    color: var(--lpx-content-text);
  }
}

.ngx-datatable.material {
  background: var(--lpx-card-bg);
}

abp-page {
  h1 {
    margin-bottom: 0.5em !important;
  }
}

abp-advanced-entity-filters {
  .card {
    margin: 1em 0;
  }
}

.ngx-datatable {
  .datatable-body-cell {
    display: flex;
    align-items: center;
  }
}

.btn-primary,
.btn-secondary {
  --bs-btn-active-bg: var(--lpx-card-bg);
  --bs-btn-active-border-color: var(--lpx-card-bg);
  --bs-btn-active-border-color: var(--mainColor);
}

.btn-primary {
  background-color: var(--mainColor) !important;
  border-color: var(--mainColor) !important;

  &:hover {
    @extend .btn-primary;
  }
}

.btn-secondary {
  color: var(--mainColor) !important;
  background-color: var(--lpx-card-bg) !important;
  border-color: var(--mainColor) !important;

  &:hover {
    @extend .btn-secondary;
  }
}

.btn-outline-primary,
.btn-outline-secondary {
  @extend .btn-secondary;
}

.form-check-input {
  &:checked {
    background-color: var(--mainColor);
    border-color: var(--mainColor) !important;
  }
}

.nav-pills {
  --bs-nav-pills-link-active-color: white;
  --bs-nav-pills-link-active-bg: var(--mainColor);
}

.list-group-item {
  &.active {
    --bs-list-group-active-bg: var(--mainColor);
    --bs-list-group-active-color: white;
  }

  &:hover {
    --bs-list-group-action-hover-bg: var(--mainColor);
    opacity: 0.9;
    color: white;
  }
}

// Material Overrides
// Note: We shouldn't have to do this, but there's a bug https://github.com/angular/components/issues/26056
.mdc-button.mat-primary {
  // color: white !important;
}

// Override material's error so it'll align better with the inputs
.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  top: -5px !important;
}

// Overrides sorting icon
.ngx-datatable.material .datatable-header .datatable-header-cell .datatable-icon-up::before {
  content: '\f286' !important;
}

.ngx-datatable.material .datatable-header .datatable-header-cell .datatable-icon-down::before {
  content: '\f282' !important;
}

// Fixes Mat-select cutting-up content
.mat-mdc-select-value {
  line-height: 2;
}
