// This file is for styling UI Elements that exist outside
// Angular's Style Encapsulation

// Profile Dropdown on Header
.profile-menu-dropdown {
    &.mat-mdc-menu-panel {
      &.mat-mdc-menu-panel {
        min-width: unset;
        max-width: unset;
        min-height: auto;
        border-radius: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        overflow: {
          y: auto;
          x: hidden;
        }
        ul {
          padding-left: 0;
          list-style-type: none;
  
          li {
            a {
              display: block;
              font-size: 15px;
              position: relative;
              padding: 7px 13px 7px 37px;
              color: var(--paragraphColor);
  
              i {
                top: 50%;
                left: 15px;
                position: absolute;
                transform: translateY(-50%);
              }
              &:hover {
                background-color: #f8fafc;
              }
            }
          }
        }
        .mat-mdc-menu-content {
          padding: 10px 0;
        }
      }
    }
  }
  
  /* Max width 767px */
  @media only screen and (max-width: 767px) {
    .profile-menu-dropdown {
      &.mat-mdc-menu-panel {
        &.mat-mdc-menu-panel {
          width: 120px;
  
          ul {
            li {
              a {
                font-size: 14px;
                padding: 6px 10px 6px 31px;
  
                i {
                  left: 10px;
                }
              }
            }
          }
          .mat-mdc-menu-content {
            padding: 5px 0;
          }
        }
      }
    }
  }
  
  /* Min width 768px to Max width 991px */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .profile-menu-dropdown {
      &.mat-mdc-menu-panel {
        &.mat-mdc-menu-panel {
          ul {
            li {
              a {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  