$popover-border-color: #e1e1e1;
$popover-icon-color: #969696;

.notifications-popover {
  &.mat-mdc-menu-panel.mat-mdc-menu-panel {
    max-width: 370px;
  }

  &--header {
    border-bottom: 1px solid $popover-border-color;

    h4 {
      font-size: 1.1em;
      font-weight: 600;
    }
  }

  &--body {
    padding: 0;
    list-style: none;

    li {
      border-bottom: 1px solid $popover-border-color;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &--item {
    display: flex;
    padding: 1em;

    p {
      margin: 0;
      width: 280px;
      line-height: 1.2;

      time {
        color: $popover-icon-color;
        display: flex;
        align-items: center;
        font-size: 0.9em;
        margin-top: 0.4em;

        .material-symbols-outlined {
          font-size: 1em;
          margin-right: 0.2em;
        }
      }
    }

    .material-symbols-outlined {
      font-size: 1.4em;
      margin-right: 0.5em;
    }
  }

  &--button {
    display: flex;
    justify-content: center;
    border-top: 1px solid $popover-border-color;

    button {
      margin-top: 0.3em;
    }
  }

  .material-symbols-outlined {
    color: $popover-icon-color;
  }
}
