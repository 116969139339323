@use '@angular/material' as mat;
@include mat.core();

// General utilities
@import 'utils';

// Material headline-5
$flyguys-pilot-h1: mat.define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 600,
  $font-size: em(32),
);

// Material headline-6
$flyguys-pilot-h2: mat.define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 600,
  $font-size: em(28),
);

// Material subtitle-1
$flyguys-pilot-h3: mat.define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 600,
  $font-size: em(26),
);

// Material subtitle-2
$flyguys-pilot-h4: mat.define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 600,
  $font-size: em(24),
);

$flyguys-pilot-h5: mat.define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 600,
  $font-size: em(20),
);

$flyguys-pilot-h6: mat.define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 600,
  $font-size: em(18),
);

$flyguys-pilot-body-1: mat.define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 400,
  $font-size: em(16),
);

$flyguys-pilot-body-2: mat.define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 400,
  $font-size: em(14),
);

$flyguys-pilot-caption: mat.define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 400,
  $font-size: em(12),
);

$flyguys-pilot-typography-config: mat.define-typography-config(
  $headline-5: $flyguys-pilot-h1,
  $headline-6: $flyguys-pilot-h2,
  $subtitle-1: $flyguys-pilot-h3,
  $subtitle-2: $flyguys-pilot-h4,
  $body-1: $flyguys-pilot-body-1,
  $body-2: $flyguys-pilot-body-2,
  $caption: $flyguys-pilot-caption,
);

$flyguys-pilot-primary-palette: (
  50: #f7ebe6,
  100: #ebcec0,
  200: #dead97,
  300: #d08c6d,
  400: #c6734d,
  500: #bc5a2e,
  600: #b65229,
  700: #ad4823,
  800: #a53f1d,
  900: #972e12,
  A100: #ffd2c9,
  A200: #ffa896,
  A400: #ff7e63,
  A700: #ff6949,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$flyguys-pilot-accent-palette: (
  50: #e6e8e7,
  100: #c2c5c4,
  200: #999f9d,
  300: #707875,
  400: #515b58,
  500: #323e3a,
  600: #2d3834,
  700: #26302c,
  800: #1f2825,
  900: #131b18,
  A100: #61ffca,
  A200: #2effb9,
  A400: #00faa7,
  A700: #00e096,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$flyguys-pilot-warn-palette: (
  50: #fae6e6,
  100: #f2c1c1,
  200: #e99797,
  300: #e06d6d,
  400: #da4e4e,
  500: #d32f2f,
  600: #ce2a2a,
  700: #c82323,
  800: #c21d1d,
  900: #b71212,
  A100: #ffe7e7,
  A200: #ffb4b4,
  A400: #ff8181,
  A700: #ff6868,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$flyguys-pilot-primary: mat.define-palette($flyguys-pilot-primary-palette, 500);

$flyguys-pilot-accent: mat.define-palette($flyguys-pilot-accent-palette, 500);

$flyguys-pilot-warn: mat.define-palette($flyguys-pilot-warn-palette, 500);

$flyguys-pilot-theme: mat.define-light-theme(
  (
    color: (
      primary: $flyguys-pilot-primary,
      accent: $flyguys-pilot-accent,
      warn: $flyguys-pilot-warn,
    ),
    typography: $flyguys-pilot-typography-config,
  )
);

@include mat.typography-hierarchy($flyguys-pilot-typography-config);
@include mat.all-component-themes($flyguys-pilot-theme);

// AG Grid
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

// Tagus SCSS
@import './globals/tagus';

// Variables
@import './variables';

// Overrides
@import './overrides';

// General Global Elements
@import './globals/elements';

// Utilities SCSS
@import './globals/utilities';

// UI Kit SCSS
@import './globals/ui-kit';

// Form SCSS
@import './globals/form';

// Sidemenu SCSS
@import './globals/sidebar';

@import './globals/buttons';

@import './globals/notifications';

@import './components/action-framework';

// Reset from Tagus/Material
html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  color: var(--blackColor);
  background-color: #f5f5f5;
}

a {
  transition: var(--transition);
  color: var(--blackColor);
  text-decoration: none;
  outline: 0 !important;

  &:hover {
    text-decoration: none;
    color: var(--mainColor);
  }
}

img {
  max-width: 100%;
  height: auto;
}

button {
  font: {
    family: var(--fontFamily) !important;
    size: var(--fontSize) !important;
  }
}

// @TODO Remove this code and implement the proper common styling in the tables.scss file
.ngx-datatable.material .datatable-header .datatable-header-cell {
  color: var(--text-primary, rgba(0, 0, 0, 0.87)) !important;
  font-feature-settings:
    'clig' off,
    'liga' off;
  text-transform: none;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 24px;
  letter-spacing: 0.17px;
}

// @TODO After fixing the issue listed above, move this file to the top
@import './globals/tables';

.breadcrumb {
  border: none;
  font-size: 16px;
  line-height: 1.5;
}

.mdc-tab {
  font-family: 'Open Sans', 'Roboto', sans-serif;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.material-symbols-rounded {
  color: #707070;
  font-weight: 200;
  font-size: 20px;
}

.cdk-overlay-pane {
  .mat-mdc-option {
    min-height: 38px !important;

    .mdc-list-item__primary-text {
      font-size: 16px !important;
    }
  }
}

.white-snackbar > .mdc-snackbar__surface {
  background-color: var(--mainColor) !important;
}

.cdk-overlay-connected-position-bounding-box,
.cdk-global-overlay-wrapper,
.cdk-overlay-backdrop,
.cdk-overlay-container {
  z-index: 10000;
  overflow: auto;
}

.message-snackbar > .mdc-snackbar__surface {
  background-color: var(--mainColor) !important;
}

// Action Framework Popup
// This is outside of the style encapsulation, hence why is here
lib-filter-dialog {
  .filter-popup {
    padding: 10px !important;

    .filter-action-buttons {
      padding-top: 10px !important;
      border-top: 1px solid #d7d7d7;
    }

    .filter-options-container {
      p {
        font-size: 15px !important;
        line-height: 1 !important;
        padding: 5px 10px;
        margin: 0;

        &:hover {
          background-color: #d7d7d7;
        }
      }
    }

    .mat-mdc-form-field {
      width: 100%;
    }

    .radios {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #d7d7d7;
      padding-bottom: 15px;
    }
  }
}

.border-none {
  border: none !important;
}

.pin-notification {
  font-size: 1.2em;
  color: #aac59fbd;
}

.message-style {
  margin-left: 15px;
}

.div-container {
  width: 100%;
}

.div-icon {
  text-align: center;
}

.manager-folder-icon {
  font-size: em(61);
}

.deliverable-files-content .mat-mdc-tab-body-content {
  overflow-x: hidden !important;
}

.file-tree-container {
  border: solid 1px #dfdfdf;
  padding: 7px;
  border-radius: 12px;
}

// thumbnail styles.
.video img {
  width: 100%;
  position: relative;
}

.video {
  position: relative;
}

.play-button-outer {
  width: 50px;
  height: 50px;
  background-color: #a09f9f;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-button {
  margin: 0 auto;
  top: 30%;
  position: relative;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 0 12px 17px;
  border-color: transparent transparent transparent #fff;
  opacity: 0.75;
}

.play-button-outer:hover .play-button {
  opacity: 1;
}

// Fixes Uppy's close button being outside of the drop area
.uppy-Dashboard-close {
  right: 10px !important;
  top: 3px !important;
  color: black !important;
  font-size: 30px !important;
}

// Use this class to reduce the height of form-fields/inputs
.compressed-form-field-density {
  @include mat.form-field-density(minimum);
}

// Disable bug reporting for small screen devices
@media only screen and (max-width: 767px) {
  #birdeatsbug-sdk {
    display: none;
  }
}

.card-text {
  color: rgba(0, 0, 0, 0.87) !important;
}

.preserve-linebreaks {
  white-space: pre-wrap;
}

// Allows instance of video to use its parent weight instead of
// the full video frame
.azuremediaplayer {
  height: 100%;
  width: 100%;
}

// Use this class when an element contains output from flg-rich-text-input
.flg-editor-output {
  white-space: pre-wrap;
  line-height: 1.3;

  a {
    color: var(--linkColor) !important;
    text-decoration: underline;
  }

  h1 {
    font-size: 17px;
    margin-bottom: 5px;
  }

  p {
    font-size: 15px;
  }

  li {
    p {
      margin: 0 !important;
    }
  }
}

// Use this as a helper to style text with the app's main color
.flyguys-orange {
  color: var(--mainColor);
}
